import React, { useEffect, useState, useRef } from 'react';
import {
    AppBar,
    Toolbar,
    Button,
    Typography,
    Box,
    IconButton,
    useScrollTrigger,
    Slide,
    Container,
    Grid,
    useMediaQuery,
    useTheme
} from '@mui/material';
import { styled } from '@mui/material/styles';
import MenuIcon from '@mui/icons-material/Menu';
import Collection from './Collection';
import Legacy from './Legacy';
import Purpose from './Purpose';
import Footer from './Footer';
import { useNavigate } from 'react-router';
import AnimatedText from './AnimatedText';
import { useLocation } from 'react-router-dom';
import ContactUsDashboard from './ContactUsDashboard';

// Custom styled components
const StyledAppBar = styled(AppBar)(({ theme }) => ({
    background: 'rgba(255, 255, 255, 0.1)',
    backdropFilter: 'blur(10px)',
    boxShadow: '0 4px 6px rgba(0,0,0,0.1)',
    transition: 'background-color 0.3s ease',
}));

const NavButton = styled(Button)(({ theme }) => ({
    color: 'white',
    textTransform: 'none',
    fontSize: '0.9rem',
    padding: '6px 12px',
    '&:hover': {
        background: 'rgba(255, 255, 255, 0.1)',
    },
    transition: 'color 0.3s ease',
    '&.scrolled': {
        color: '#1A5F7A', // Blue color when scrolled more than 100px
    },
}));

const ActionButton = styled(Button)(({ theme }) => ({
    color: 'white',
    textTransform: 'none',
    fontSize: '0.9rem',
    padding: '6px 16px',
    border: '1px solid white',
    borderRadius: '4px',
    marginLeft: '8px',
    '&:hover': {
        background: 'rgba(255, 255, 255, 0.1)',
    },
    transition: 'color 0.3s ease, border-color 0.3s ease',
    '&.scrolled': {
        color: '#1A5F7A', // Blue color when scrolled more than 100px
        borderColor: '#1A5F7A', // Blue border when scrolled
    },
}));

const HeroButton = styled(Button)({
    backgroundColor: 'white',
    color: '#1a1a1a',
    textTransform: 'none',
    padding: '12px 32px',
    borderRadius: '4px',
    fontSize: '1rem',
    '&:hover': {
        backgroundColor: 'rgba(255, 255, 255, 0.9)',
    },
});

const LogoTypography = styled(Typography)(({ theme }) => ({
    fontFamily: "Cormorant Infant",
    fontWeight: 200,
    color: 'white',
    transition: 'color 0.3s ease',
    '&.scrolled': {
        color: '#1A5F7A', // Blue color when scrolled more than 100px
    },
    [theme.breakpoints.down('sm')]: {
        fontSize: '2.5rem',
    },
}));

interface Props {
    window?: () => Window;
    children: React.ReactElement;
}






function HideOnScroll(props: Props) {
    const { children, window } = props;
    const trigger = useScrollTrigger({
        target: window ? window() : undefined,
    });

    return (
        <Slide appear={false} direction="down" in={!trigger}>
            {children}
        </Slide>
    );
}

const HomePage = () => {
    const [scrolled, setScrolled] = useState(false);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const navigate = useNavigate();
    const purposeRef = useRef<HTMLDivElement>(null);
    const contactusRef = useRef<HTMLDivElement>(null);
    const contactLink = location.pathname === "/contact-us-dashboard";

    const handleNavigation = (path: any) => {
        if (path === '/services') {
            purposeRef.current?.scrollIntoView({ behavior: 'smooth' });
        }
        else if (path === '/contactus') {
            contactusRef.current?.scrollIntoView({ behavior: 'smooth' });
        }
        else {
            navigate(path);
        }
    };
    useEffect(() => {
        const handleScroll = () => {

            setScrolled(window.scrollY > 100);
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <Box sx={{ minHeight: '100vh', width: '100%', overflow: 'hidden' }}>
            <HideOnScroll>
                <StyledAppBar
                    sx={{
                        backgroundColor: scrolled ? 'rgba(255, 255, 255, 0.1)' : 'transparent',
                        backdropFilter: scrolled ? 'blur(10px)' : 'none',
                        boxShadow: scrolled ? '0 4px 6px rgba(0,0,0,0.1)' : 'none',
                        transition: 'all 0.3s ease',
                    }}>
                    <Toolbar sx={{ px: '24px', justifyContent: 'space-between' }}>
                        {isMobile ? (
                            <>
                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                    <LogoTypography className={scrolled ? 'scrolled' : ''}>SD</LogoTypography>
                                </Box>
                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                    <NavButton
                                        className={scrolled ? 'scrolled' : ''}
                                        onClick={() => handleNavigation('/login')} // Redirect to login
                                    >
                                        Login
                                    </NavButton>
                                    <ActionButton
                                        variant="outlined"
                                        className={scrolled ? 'scrolled' : ''}
                                        onClick={() => handleNavigation('/signup')} // Redirect to signup
                                    >
                                        Sign Up
                                    </ActionButton>

                                </Box>
                            </>
                        ) : (
                            <>
                                {/* Desktop Navigation */}
                                <Box sx={{ display: 'flex', gap: 1 }}>
                                    <NavButton
                                        className={scrolled ? 'scrolled' : ''}
                                        onClick={() => handleNavigation('/home')}
                                    >
                                        Home
                                    </NavButton>
                                    <NavButton
                                        className={scrolled ? 'scrolled' : ''}
                                        onClick={() => handleNavigation('/services')}
                                    >
                                        Services
                                    </NavButton>
                                    <NavButton
                                        className={scrolled ? 'scrolled' : ''}
                                        onClick={() => handleNavigation('/plans')}
                                    >
                                        Pricing
                                    </NavButton>
                                </Box>

                                <Box
                                    sx={{
                                        position: 'absolute',
                                        left: '50%',
                                        transform: 'translateX(-50%)',
                                        display: 'flex',
                                        alignItems: 'center'
                                    }}
                                >
                                    <LogoTypography
                                        sx={{
                                            fontSize: scrolled ? '2rem' : '3rem',
                                            transition: 'all 0.3s ease',
                                        }}
                                        className={scrolled ? 'scrolled' : ''}
                                    >
                                        Superb Diamonds
                                    </LogoTypography>
                                </Box>

                                <Box sx={{ display: 'flex', alignItems: 'center', marginLeft: 'auto' }}>
                                    <NavButton
                                        className={scrolled ? 'scrolled' : ''}
                                        onClick={() => handleNavigation('/login')} // Redirect to login
                                    >
                                        Login
                                    </NavButton>
                                    <ActionButton
                                        variant="outlined"
                                        className={scrolled ? 'scrolled' : ''}
                                        onClick={() => handleNavigation('/signup')} // Redirect to signup
                                    >
                                        Sign Up
                                    </ActionButton>

                                    <Box
                                        sx={{
                                            height: '24px',
                                            borderLeft: scrolled
                                                ? '1px solid #1A5F7A'
                                                : '1px solid rgba(255,255,255,0.5)',
                                            mx: 2,
                                            transition: 'border-color 0.3s ease'
                                        }}
                                    />
                                    <NavButton className={scrolled ? 'scrolled' : ''} onClick={() => handleNavigation('/contactus')}>Contact Us</NavButton>
                                </Box>
                            </>
                        )}
                    </Toolbar>
                </StyledAppBar>
            </HideOnScroll>

            {/* Hero Section */}
            <Box
                sx={{
                    height: '100vh',
                    width: '100%',
                    position: 'relative',
                    display: 'flex',
                    alignItems: 'center',
                    '&::before': {
                        content: '""',
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        backgroundColor: 'rgba(0, 0, 0, 0.3)',
                        zIndex: 1,
                    },
                }}
            >
                {/* Background Image */}
                <Box
                    component="img"
                    src="./sdjewels (2).jpg"
                    alt="Woman in floral dress with jewelry"
                    sx={{
                        position: 'absolute',
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover',
                    }}
                />
                <Box
                    sx={{
                        position: 'relative',
                        zIndex: 2,
                        px: '24px',
                        width: '100%'
                    }}
                >
                    <Box maxWidth="600px">
                        <AnimatedText text="Artful mastery within each Precious gem." delay={50} />
                        {/* <Typography
                            variant="h1"
                            component="h1"
                            sx={{
                                color: 'white',
                                fontFamily: 'serif',
                                fontSize: { xs: '2.5rem', md: '4rem' },
                                lineHeight: 1.2,
                                mb: 3,
                            }}
                        >
                            Artful mastery within each{' '}
                            <Box component="span" sx={{ fontStyle: 'italic' }}>
                                Precious
                            </Box>{' '}
                            gem.
                        </Typography> */}

                        <Typography
                            variant="h6"
                            sx={{
                                color: 'rgba(255, 255, 255, 0.9)',
                                fontWeight: 'normal',
                                mb: 4,
                                maxWidth: '500px',
                            }}
                        >
                            Each gem embodies meticulous craftsmanship, a timeless testament to
                            unparalleled skill and elegance.
                        </Typography>

                        <HeroButton variant="contained" onClick={() => handleNavigation('/login')}>
                            Discover Now
                        </HeroButton>
                    </Box>
                </Box>
            </Box>



            <Box ref={purposeRef} >
                <Purpose />
            </Box>
            <Collection />
            <Legacy />
            <Box ref={contactusRef}>

                <Footer />
            </Box>



        </Box>
    );
};

export default HomePage;