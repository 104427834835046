import React, { useState, ChangeEvent, useContext, useMemo } from 'react';
import {
    FormControl,
    Grid,
    TextField,
    FormLabel,
    MenuItem,
    Select,
    SelectChangeEvent,
    ToggleButtonGroup,
    ToggleButton,
    InputLabel,
    Typography,
    Input,
    Button
} from '@mui/material';
import { useLocation } from 'react-router-dom';
import { PurchaseContext } from '../../Purchase/Context/purchaseProviderContext';
import { clarities, colors, jewelryTypes } from '../../../Constants/FilterFormItems';

export default function JeweleryDetails() {
    const urllocation = useLocation();
    const { filterFormState, filterFormDispatch } = useContext(PurchaseContext);
    const [filterFormData, setFilterFormData] = useState<any>(filterFormState);
    const [imagePreview, setImagePreview] = useState<string | null>(null);
    const onImageUpload = (imageBlob: Blob | null) => {
        // Handle the image upload logic here
        console.log('Image uploaded:', imageBlob);
    };

    const handleImageChange = (event: ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setImagePreview(reader.result as string);
                onImageUpload(file);
            };
            reader.readAsDataURL(file);
        } else {
            setImagePreview(null);
            onImageUpload(null);
        }
    };


    // Gold Purity Options
    const goldPurityOptions = useMemo(() => [
        '6K', '8K', '10K', '12K', '14K', '18K', '20K', '22K', '24K'
    ], []);

    // Gold Type Options
    const goldTypeOptions = useMemo(() => [
        'Rose Gold', 'White Gold', 'Yellow Gold'
    ], []);

    // Handle Input Change
    const handleInputChange = (field: string) => (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent) => {
        filterFormDispatch({
            type: 'SET_FILTER_FORM',
            payload: {
                ...filterFormState,
                [field]: event.target.value
            }
        });
    };

    // Handle Toggle Button Change
    const handleToggleChange = (field: string) => (_event: React.MouseEvent<HTMLElement>, newValue: string) => {
        if (newValue !== null) {
            filterFormDispatch({
                type: 'SET_FILTER_FORM',
                payload: {
                    ...filterFormState,
                    [field]: newValue
                }
            });
        }
    };

    // Common TextField Props
    const commonTextFieldProps = {
        size: "small" as const,
        fullWidth: true,
        sx: {
            '& .MuiOutlinedInput-root': {
                backgroundColor: 'rgba(255, 253, 208, 0.9)',
                borderRadius: 2,
                boxShadow: 'inset 0 2px 4px rgba(218, 165, 32, 0.1)',
                '& fieldset': {
                    borderColor: 'rgba(218, 165, 32, 0.5)'
                }
            }
        }
    };

    return (
        <FormControl
            fullWidth
            sx={{
                background: 'linear-gradient(145deg, #FAF0BE, #E6C229)',
                padding: 2,
                borderRadius: 3,
                boxShadow: '0 4px 6px rgba(0,0,0,0.1)',
                mt: urllocation.pathname === "/purchase" ? 2 : 0,
                border: '1px solid rgba(218, 165, 32, 0.3)'
            }}
        >
            <FormLabel sx={{
                mb: 2,
                color: '#8B4513',
                fontWeight: 'bold',
                fontSize: '1.1rem',
                textShadow: '1px 1px 2px rgba(218, 165, 32, 0.2)'
            }}>
                Gold and Diamond Details
            </FormLabel>

            <Grid container spacing={2}>
                {/* Gold Details Section */}
                <Grid item xs={12} sm={6} md={3}>
                    <TextField
                        {...commonTextFieldProps}
                        label="Gold Weight (gms)"
                        type="number"
                        value={filterFormState.gold_weight || ''}
                        onChange={handleInputChange('gold_weight')}
                        inputProps={{ step: "0.01", min: "0" }}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                    <TextField
                        {...commonTextFieldProps}
                        select
                        label="Gold Purity"
                        value={filterFormState.gold_purity || ''}
                        onChange={handleInputChange('gold_purity')}
                    >
                        {goldPurityOptions.map((purity) => (
                            <MenuItem key={purity} value={purity}>
                                {purity}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                    <TextField
                        {...commonTextFieldProps}
                        select
                        label="Gold Type"
                        value={filterFormState.gold_type || ''}
                        onChange={handleInputChange('gold_type')}
                    >
                        {goldTypeOptions.map((type) => (
                            <MenuItem key={type} value={type}>
                                {type}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>

                {/* Diamond Details Section */}
                <Grid item xs={12} sm={6} md={3}>
                    <TextField
                        {...commonTextFieldProps}
                        label="Diamond Weight (cts)"
                        type="number"
                        value={filterFormState.diamond_weight || ''}
                        onChange={handleInputChange('diamond_weight')}
                        inputProps={{ step: "0.01", min: "0" }}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                    <TextField
                        {...commonTextFieldProps}
                        label="Number of Diamond Pieces"
                        type="number"
                        value={filterFormState.diamond_pieces || ''}
                        onChange={handleInputChange('diamond_pieces')}
                        inputProps={{ min: "0" }}
                    />
                </Grid>

                {/* Diamond Dimensions Section */}
                <Grid item xs={12} sm={4}>
                    <TextField
                        {...commonTextFieldProps}
                        label="Diamond Height (mm)"
                        type="number"
                        value={filterFormState.diamond_height || ''}
                        onChange={handleInputChange('diamond_height')}
                        inputProps={{ step: "0.01", min: "0" }}
                    />
                </Grid>
                <Grid item xs={12} sm={4}>
                    <TextField
                        {...commonTextFieldProps}
                        label="Diamond Width (mm)"
                        type="number"
                        value={filterFormState.diamond_width || ''}
                        onChange={handleInputChange('diamond_width')}
                        inputProps={{ step: "0.01", min: "0" }}
                    />
                </Grid>
                <Grid item xs={12} sm={4}>
                    <TextField
                        {...commonTextFieldProps}
                        label="Diamond Length (mm)"
                        type="number"
                        value={filterFormState.diamond_length || ''}
                        onChange={handleInputChange('diamond_length')}
                        inputProps={{ step: "0.01", min: "0" }}
                    />
                </Grid>

                {/* Jewelry Type Section */}
                <Grid item xs={12} sm={4}>
                    <FormControl fullWidth>
                        <InputLabel id="jewelry-type-label">Jewelry Type</InputLabel>
                        <Select
                            {...commonTextFieldProps}
                            labelId="jewelry-type-label"
                            id="jewelry-type"
                            value={filterFormState.jewelryType || ''}
                            label="Jewelry Type"
                            onChange={handleInputChange('jewelryType')}
                        >
                            {jewelryTypes.map((type: string) => (
                                <MenuItem key={type} value={type}>
                                    {type}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid container spacing={2} alignItems="center">
                    <Grid item>
                        <label htmlFor="upload-image">
                            <Input
                                inputProps={{ accept: "image/*" }}
                                id="upload-image"
                                type="file"
                                onChange={handleImageChange}
                            />
                            <Button variant="contained" component="span">
                                Upload Image
                            </Button>
                        </label>
                    </Grid>
                    {imagePreview && (
                        <Grid item>
                            <img
                                src={imagePreview}
                                alt="Jewelery Preview"
                                style={{ maxWidth: '100px', maxHeight: '100px', borderRadius: '8px' }}
                            />
                        </Grid>
                    )}
                </Grid>

                {/* Color Section */}
                <Grid item xs={12}>
                    <FormControl fullWidth>
                        <FormLabel sx={{ paddingBottom: 1, fontSize: { xs: '0.9rem', sm: '1rem' }, fontWeight: 500 }}>
                            Color
                        </FormLabel>
                        <ToggleButtonGroup
                            value={filterFormState.color}
                            onChange={handleToggleChange('color')}
                            aria-label="color selection"
                            sx={{
                                display: 'flex',
                                flexWrap: 'wrap',
                                gap: 0.5,
                                '& .MuiToggleButtonGroup-grouped': {
                                    backgroundColor: 'rgba(255, 253, 208, 0.9)',
                                    border: '1px solid rgba(0, 0, 0, 0.12)',
                                    borderRadius: 3,
                                    marginLeft: '0 !important'
                                }
                            }}
                        >
                            {colors.map((color) => (
                                <ToggleButton
                                    key={color}
                                    value={color}
                                    aria-label={color}
                                    sx={{
                                        flex: { xs: '1 0 calc(20% - 8px)', sm: '1 0 calc(10% - 8px)' },
                                        minWidth: { xs: '40px', sm: '48px' },
                                        minHeight: { xs: '36px', sm: '40px' },
                                        padding: { xs: 0.5, sm: 1 },
                                        borderRadius: '12px !important',
                                        fontSize: { xs: '0.8rem', sm: '0.875rem' },
                                        '&.Mui-selected': {
                                            backgroundColor: '#FFD700', // Gold color
                                            color: '#000', // Black text for better contrast
                                            '&:hover': {
                                                backgroundColor: '#FFD700', // Keep gold on hover
                                            },
                                        },
                                    }}
                                >
                                    {color}
                                </ToggleButton>
                            ))}
                        </ToggleButtonGroup>
                    </FormControl>
                </Grid>

                {/* Clarity Section */}
                <Grid item xs={12}>
                    <FormControl fullWidth>
                        <FormLabel sx={{ mb: { xs: 1, sm: 2 } }}>Clarity</FormLabel>
                        <ToggleButtonGroup
                            value={filterFormState.clarity}
                            onChange={handleToggleChange('clarity')}
                            aria-label="clarity selection"
                            fullWidth
                            sx={{
                                display: { xs: 'grid', sm: 'flex' },
                                gridTemplateColumns: { xs: 'repeat(4, 1fr)', sm: 'none' },
                                gap: { xs: 1, sm: 0 },
                                '& .MuiToggleButtonGroup-grouped': {
                                    margin: 0.5,
                                    padding: 1,
                                    borderRadius: 3,
                                }
                            }}
                        >
                            {clarities.map((clarity) => (
                                <ToggleButton
                                    key={clarity}
                                    value={clarity}
                                    aria-label={clarity}
                                    sx={{
                                        backgroundColor: 'rgba(255, 253, 208, 0.9)',
                                        margin: 0.5,
                                        padding: 1,
                                        borderRadius: 3,
                                        '&.Mui-selected': {
                                            backgroundColor: '#FFD700', // Gold color
                                            color: '#000', // Black text for better contrast
                                            '&:hover': {
                                                backgroundColor: '#FFD700', // Keep gold on hover
                                            },
                                        },
                                    }}
                                >
                                    {clarity}
                                </ToggleButton>
                            ))}
                        </ToggleButtonGroup>
                    </FormControl>
                </Grid>
            </Grid>
        </FormControl>
    );
}