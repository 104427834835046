import React from 'react';
import { useState } from 'react';
import {
    Card,
    CardContent,
    Button,
    Typography,
    TextField,
    Box,
    Container,
    styled,
} from '@mui/material';
import { Mail, Phone, MoveUpRight, Sparkles } from "lucide-react"
import Footer from './Footer';

// Styled components
const GradientBackground = styled(Box)({
    minHeight: '100vh',
    background: 'linear-gradient(135deg, #0f172a 0%, #1e293b 100%)',
    padding: '48px 0',
});

const HeaderSparkle = styled(Box)({
    display: 'inline-flex',
    alignItems: 'center',
    gap: '8px',
    animation: 'pulse 2s infinite',
    '@keyframes pulse': {
        '0%, 100%': {
            opacity: 1,
        },
        '50%': {
            opacity: 0.7,
        },
    },
});

const ContactLink = styled('a')({
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    color: 'rgba(203, 213, 225, 1)',
    textDecoration: 'none',
    transition: 'color 0.3s ease',
    '&:hover': {
        color: '#60A5FA',
    },
});

const GradientText = styled(Typography)({
    background: 'linear-gradient(to right, #60A5FA, #A78BFA)',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
});

const partners = [
    {
        name: 'Mehul Mehta',
        email: 'mehulkm1973@yahoo.com',
        phone: '+66 896737535',
    },
    {
        name: 'Saurabh Mehta',
        email: 'saurabhkm1@yahoo.com',
        phone: '+91 9825351170',
    },
    {
        name: 'Ankit Mehta',
        email: 'ankit.mehta22@yahoo.com',
        phone: '+91 9825188599',
    },
];

const ContactUsDashboard = () => {
    const [hoveredCard, setHoveredCard] = useState<number | null>(null);

    return (
        <>
            <GradientBackground>
                <Container maxWidth="lg">
                    {/* Header Section */}
                    <Box sx={{ textAlign: 'center', mb: 8 }}>
                        <HeaderSparkle>
                            <Sparkles style={{ width: 32, height: 32, color: "white" }} />
                            <GradientText variant="h2" sx={{ fontWeight: 'bold' }}>
                                Contact Us
                            </GradientText>
                            <Sparkles style={{ width: 32, height: 32, color: "white" }} />
                        </HeaderSparkle>
                        <Typography variant="subtitle1" sx={{ color: 'rgba(203, 213, 225, 1)', maxWidth: '600px', mx: 'auto', mt: 2 }}>
                            Not sure what you need? You can contact us using the details below or suggest an idea.
                        </Typography>
                    </Box>

                    {/* Partners Grid */}
                    <Box sx={{
                        display: 'grid',
                        gridTemplateColumns: {
                            xs: '1fr',
                            md: '1fr 1fr',
                            lg: '1fr 1fr 1fr'
                        },
                        gap: 3,
                        mb: 8
                    }}>
                        {partners.map((partner, index) => (
                            <Card
                                key={index}
                                sx={{
                                    position: 'relative',
                                    overflow: 'hidden',
                                    bgcolor: 'rgba(30, 41, 59, 0.8)',
                                    border: '1px solid rgba(51, 65, 85, 0.5)',
                                    transform: hoveredCard === index ? 'scale(1.05)' : 'scale(1)',
                                    transition: 'all 0.3s ease',
                                    '&:hover': {
                                        boxShadow: '0 0 20px rgba(59, 130, 246, 0.2)',
                                    },
                                }}
                                onMouseEnter={() => setHoveredCard(index)}
                                onMouseLeave={() => setHoveredCard(null)}
                            >
                                <CardContent>
                                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                        <Typography variant="h6" sx={{ color: 'white' }}>
                                            {partner.name}
                                        </Typography>
                                        <MoveUpRight style={{
                                            width: 16,
                                            height: 16,
                                            transition: 'transform 0.3s ease',
                                            transform: hoveredCard === index ? 'translate(4px, -4px)' : 'none'
                                        }} />
                                    </Box>
                                    <Box sx={{ mt: 2, display: 'flex', flexDirection: 'column', gap: 1 }}>
                                        <ContactLink href={`mailto:${partner.email}`}>
                                            <Mail style={{ width: 16, height: 16 }} />
                                            <Typography variant="body2">{partner.email}</Typography>
                                        </ContactLink>
                                        <ContactLink href={`tel:${partner.phone}`}>
                                            <Phone style={{ width: 16, height: 16 }} />
                                            <Typography variant="body2">{partner.phone}</Typography>
                                        </ContactLink>
                                    </Box>
                                </CardContent>
                            </Card>
                        ))}
                    </Box>

                    {/* Developer Contact */}
                    <Card sx={{
                        bgcolor: 'rgba(30, 41, 59, 0.8)',
                        mb: 4,
                        position: 'relative',
                        overflow: 'hidden',
                        '&::before': {
                            content: '""',
                            position: 'absolute',
                            inset: 0,
                            background: 'linear-gradient(to right, rgba(96, 165, 250, 0.1), rgba(167, 139, 250, 0.1), rgba(96, 165, 250, 0.1))',
                            animation: 'gradient 15s ease infinite',
                            backgroundSize: '200% 200%',
                        },
                    }}>
                        <CardContent sx={{ position: 'relative' }}>
                            <Typography variant="h5" sx={{ color: 'white', mb: 2 }}>
                                Developer Contact
                            </Typography>
                            <Typography variant="h6" sx={{ color: 'white', mb: 2 }}>
                                Krish Mehta
                            </Typography>
                            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                                <ContactLink href="mailto:krish.mehta.3822@gmail.com">
                                    <Mail style={{ width: 16, height: 16 }} />
                                    <Typography variant="body2">krish.mehta.3822@gmail.com</Typography>
                                </ContactLink>
                                <ContactLink href="tel:+918401265994">
                                    <Phone style={{ width: 16, height: 16 }} />
                                    <Typography variant="body2">+91 8401265994</Typography>
                                </ContactLink>
                            </Box>
                        </CardContent>
                    </Card>

                    {/* Contact Form */}
                    <Card sx={{ bgcolor: 'rgba(30, 41, 59, 0.8)' }}>
                        <CardContent>
                            <GradientText variant="h5" sx={{ mb: 3 }}>
                                Ask a Question or Suggest an Idea
                            </GradientText>
                            <Box component="form" sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
                                <Box sx={{
                                    display: 'grid',
                                    gridTemplateColumns: { xs: '1fr', md: '1fr 1fr' },
                                    gap: 3
                                }}>
                                    <TextField
                                        placeholder="Your Name"
                                        variant="outlined"
                                        fullWidth
                                        sx={{
                                            '& .MuiOutlinedInput-root': {
                                                color: 'white',
                                                bgcolor: 'rgba(15, 23, 42, 0.8)',
                                                '& fieldset': {
                                                    borderColor: 'rgba(51, 65, 85, 0.5)',
                                                },
                                                '&:hover fieldset': {
                                                    borderColor: 'rgba(59, 130, 246, 0.5)',
                                                },
                                                '&.Mui-focused fieldset': {
                                                    borderColor: '#60A5FA',
                                                },
                                            },
                                        }}
                                    />
                                    <TextField
                                        placeholder="Your Email"
                                        type="email"
                                        variant="outlined"
                                        fullWidth
                                        sx={{
                                            '& .MuiOutlinedInput-root': {
                                                color: 'white',
                                                bgcolor: 'rgba(15, 23, 42, 0.8)',
                                                '& fieldset': {
                                                    borderColor: 'rgba(51, 65, 85, 0.5)',
                                                },
                                                '&:hover fieldset': {
                                                    borderColor: 'rgba(59, 130, 246, 0.5)',
                                                },
                                                '&.Mui-focused fieldset': {
                                                    borderColor: '#60A5FA',
                                                },
                                            },
                                        }}
                                    />
                                </Box>
                                <TextField
                                    placeholder="Your Question or Suggestion"
                                    multiline
                                    rows={4}
                                    variant="outlined"
                                    fullWidth
                                    sx={{
                                        '& .MuiOutlinedInput-root': {
                                            color: 'white',
                                            bgcolor: 'rgba(15, 23, 42, 0.8)',
                                            '& fieldset': {
                                                borderColor: 'rgba(51, 65, 85, 0.5)',
                                            },
                                            '&:hover fieldset': {
                                                borderColor: 'rgba(59, 130, 246, 0.5)',
                                            },
                                            '&.Mui-focused fieldset': {
                                                borderColor: '#60A5FA',
                                            },
                                        },
                                    }}
                                />
                                <Button
                                    variant="contained"
                                    sx={{
                                        alignSelf: { xs: 'stretch', sm: 'flex-start' },
                                        background: 'linear-gradient(to right, #60A5FA, #A78BFA)',
                                        transition: 'all 0.3s ease',
                                        '&:hover': {
                                            background: 'linear-gradient(to right, #3B82F6, #8B5CF6)',
                                            transform: 'translateY(-2px)',
                                        },
                                    }}
                                >
                                    Submit
                                </Button>
                            </Box>
                        </CardContent>
                    </Card>
                </Container>
            </GradientBackground>
            <Footer />
        </>
    );
};

export default ContactUsDashboard;