import React from 'react';
import { Box, Grid, Button } from '@mui/material';
import { Send as SendIcon, Refresh as RefreshIcon } from '@mui/icons-material';

import JeweleryStockNumber from '../Components/JeweleryStockNumber';
import JeweleryDetails from '../Components/JeweleryDetails';

const PurchaseJewelery = () => {
    const handleSubmit = () => {
        // Handle submit logic
    };

    const handleReset = () => {
        // Handle reset logic
    };

    return (
        <Box >
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <JeweleryStockNumber />
                </Grid>
                <Grid item xs={12}>
                    <JeweleryDetails />
                </Grid>
                <Grid item xs={12} sx={{
                    display: 'flex',
                    justifyContent: 'center',

                    mt: 2
                }}>
                    <Button
                        startIcon={<SendIcon />}
                        variant="contained"
                        onClick={handleSubmit}
                        sx={{
                            background: 'linear-gradient(45deg, #FFC700 30%, #FFD700 90%)',
                            color: 'white',
                            transition: 'transform 0.2s ease-in-out',
                            '&:hover': {
                                transform: 'scale(1.05)',
                                background: 'linear-gradient(45deg, #FFD700 30%, #FFC700 90%)',
                                boxShadow: '0 4px 10px rgba(0,0,0,0.2)'
                            },
                            fontWeight: 'bold',
                            borderRadius: 3,
                            padding: '12px 24px',
                            textTransform: 'none',
                            minWidth: '150px'
                        }}
                    >
                        Submit
                    </Button>
                    <Button
                        startIcon={<RefreshIcon />}
                        variant="outlined"
                        onClick={handleReset}
                        sx={{
                            borderColor: '#FFD700',
                            color: '#FFD700',
                            transition: 'all 0.2s ease-in-out',
                            '&:hover': {
                                borderColor: '#FFC700',
                                color: '#FFC700',
                                backgroundColor: 'rgba(255,215,0,0.1)',
                                transform: 'scale(1.05)'
                            },
                            fontWeight: 'bold',
                            borderRadius: 3,
                            padding: '12px 24px',
                            textTransform: 'none',
                            minWidth: '150px'
                        }}
                    >
                        Reset
                    </Button>
                </Grid>
            </Grid>
        </Box>
    );
};

export default PurchaseJewelery;