import React from 'react';
import { Box, Typography, Link, IconButton, useMediaQuery, useTheme, Grid, Divider } from '@mui/material';
import InstagramIcon from '@mui/icons-material/Instagram';
import { Color } from '../Theme/Color';
import { useLocation } from 'react-router-dom';


const Footer: React.FC = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const location = useLocation();
    const contactLink = location.pathname === "/" ? "/contact-us-dashboard" : "/contact-us";


    const sectionStyle = {
        '& > :not(:first-of-type)': {
            marginTop: theme.spacing(1),
        },
    };

    const linkStyle = {
        textDecoration: 'none',
        '&:hover': {
            textDecoration: 'underline',
        },
    };

    return (
        <Box
            sx={{
                backgroundColor: Color.DARK_BLUE,
                color: 'white',
                padding: theme.spacing(4, 2),
                width: '100%',
            }}
        >
            <Grid container spacing={4} justifyContent="space-between">
                {/* Company Info */}
                <Grid item xs={12} md={3} sx={sectionStyle}>
                    <Typography variant="h5" fontWeight="bold" gutterBottom>
                        Superb Diamonds
                    </Typography>
                    <Typography variant="body2" sx={{ fontStyle: 'italic' }}>
                        306, Nishit Diamond Complex,<br /> Contractor khacho, Mahidarpura,<br /> 395003,Surat.

                    </Typography>
                </Grid>

                {/* Navigation Links */}
                <Grid item xs={12} md={6}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={4} sx={sectionStyle}>
                            <Typography variant="h6" fontWeight="bold" gutterBottom>
                                About Us
                            </Typography>
                            <Link href="#" color="inherit" sx={linkStyle} display="block">
                                Our Story
                            </Link>
                            <Link href="#" color="inherit" sx={linkStyle} display="block">
                                Team
                            </Link>
                        </Grid>

                        <Grid item xs={12} sm={4} sx={sectionStyle}>
                            <Typography variant="h6" fontWeight="bold" gutterBottom>
                                Contact Us
                            </Typography>
                            <Link href={contactLink} color="inherit" sx={linkStyle} display="block">
                                Support
                            </Link>
                            <Link href={contactLink} color="inherit" sx={linkStyle} display="block">
                                FAQ
                            </Link>
                        </Grid>

                        <Grid item xs={12} sm={4} sx={sectionStyle}>
                            <Typography variant="h6" fontWeight="bold" gutterBottom>
                                Terms & Conditions
                            </Typography>
                            <Link href="/t&c" color="inherit" sx={linkStyle} display="block">
                                Privacy Policy
                            </Link>
                            <Link href="/t&c" color="inherit" sx={linkStyle} display="block">
                                Legal
                            </Link>
                        </Grid>
                    </Grid>
                </Grid>

                {/* Contact Info and Social Media */}
                <Grid item xs={12} md={3} sx={sectionStyle}>
                    <Typography variant="h6" fontWeight="bold" gutterBottom>
                        Get in Touch
                    </Typography>
                    <Typography variant="body2" fontWeight="medium">
                        <Link href="mailto:superb.diamonds@gmail.com" color="inherit" sx={linkStyle}>
                            superb.diamonds@gmail.com
                        </Link>
                    </Typography>
                    <Typography variant="body2" fontWeight="medium">
                        <Link href="tel:+919825188599" color="inherit" sx={linkStyle}>
                            +91 9825188599
                        </Link>
                    </Typography>
                    <Box display="flex" gap={2} mt={2}>
                        <Box display="flex" flexDirection="column" alignItems="center">
                            <IconButton color="inherit" aria-label="Instagram - Superb Diamonds" href='https://www.instagram.com/superb.diamonds/'>
                                <InstagramIcon />
                            </IconButton>
                            <Typography variant="caption" fontWeight="light">
                                @superb.diamonds
                            </Typography>
                        </Box>
                        <Box display="flex" flexDirection="column" alignItems="center">
                            <IconButton color="inherit" aria-label="Instagram - Twinkle Diam" href='https://www.instagram.com/twinklediam/'>
                                <InstagramIcon />
                            </IconButton>
                            <Typography variant="caption" fontWeight="light">
                                @twinklediam
                            </Typography>
                        </Box>
                    </Box>
                </Grid>
            </Grid>

            {/* Separator */}
            <Divider sx={{ my: 3, backgroundColor: 'rgba(255, 255, 255, 0.2)' }} />

            {/* Copyright */}
            <Typography variant="body2" sx={{ textAlign: 'center', fontStyle: 'italic' }}>
                © 2025 Superb Diamonds | Twinkle Diam. All rights reserved.
            </Typography>
        </Box>
    );
};

export default Footer;
